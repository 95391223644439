import React from 'react';
import ReactDOM from 'react-dom';
import jquery from 'jquery';
import moment from 'moment';
//import popper from 'popper.js';
//import 'bootstrap/dist/js/bootstrap';

window.React = React;
window.ReactDOM = ReactDOM;
window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;
window.moment = moment;
//window.Popper = popper;
